
import { Component, Vue, Watch } from 'vue-property-decorator';
import { appTokenMgr, IEmployeeAppRoleManager } from '@t/employee-app-role';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { NU } from '@t/type';
import { vxm } from '@/store';
import { contractApi } from '@/wapi/contract-api';
import { imputationApi } from '@/wapi/imputation-api';
import { studioApi } from '@/wapi/studio-api';
import {
    IImputationRecap,
    IImputationRecapFilter,
    IImputationsDetaillees,
    IDictionaryGeneral
} from '@/entity/rh/imputation-recap';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { moduleApiGraph } from '@t/module-api-graph';
import { IProject } from '@/entity/project/project';
import { IStudio } from '@/entity/project/studio';
import InputElementMgt from '@c/shared/input-element-mgt.vue';
// import { BIconPencilSquare } from 'bootstrap-vue';

@Component({
    components: { InputElementMgt }
})
export default class ImputationRecapList extends Vue {
    contractLineOptions: ISelectListOption[] | undefined = [];
    studioOptions: ISelectListOption[] | undefined = [];
    imputations: IImputationRecap[] | null = [];
    imputationsDetaillees: IDictionaryGeneral = {};
    phaseSelected: any = null;
    filterUserIds: string[] = [];
    noDataFound: boolean = true;
    filterIsApply: boolean = false;
    private selectedProjectId: NU<number> = null;
    private selectedStudioId: NU<number> = null;
    private selectedContractLineId: NU<number> = null;
    private fromDate: Date | null = null;
    private toDate: Date | null = null;
    private promiseExport: boolean = false;
    private isBusy: boolean = true;
    private showModalRestitution: boolean = false;
    private newContractLineId: NU<number> = null;
    private filterData: string = '';
    private readonly: boolean = false;
    private autoload: boolean = false;
    private employeeToUpdate: any;

    showRestitution(data) {
        this.phaseSelected = data.item.phase;
        this.employeeToUpdate = data.item.employeeId;
        this.showModalRestitution = true;
    }

    hideRestitution() {
        this.showModalRestitution = false;
    }

    get currentProject(): NU<IProject> {
        return vxm.project.dropdownProject;
    }

    get isAdmin(): boolean {
        return vxm.user.isAdmin;
    }

    get projects(): IProject[] {
        let list = vxm.project.dropdownProjectListOptions;
        if (this.filterData && this.filterData !== '') {
            list = list.filter((item) => {
                return (
                    item.designation.toUpperCase().indexOf(this.filterData.toUpperCase()) > -1 ||
                    item.trigram.toUpperCase().indexOf(this.filterData.toUpperCase()) > -1
                );
            });
        }
        return list;
    }

    changeRestitution() {
        moduleApiGraph.Client.api('/me')
            .select('id')
            .get()
            .then(async (value) => {
                // call api to change phase
                if (this.selectedProjectId && this.newContractLineId) {
                    const result = await imputationApi.restitutionImputation(this.selectedProjectId, this.newContractLineId, this.phaseSelected, this.employeeToUpdate, value.id, this.formatDate(this.fromDate), this.formatDate(this.toDate));   
                }
            })
            .finally(() => {
                this.getImputationList();
            });
        this.hideRestitution();
    }

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: '', key: 'tools' },
        { label: 'Projet', key: 'project', formatter: (val: string, key?: string, item?) => val?.toUpperCase() },
        { label: 'Employé(e) ', key: 'employeeId' },
        { label: 'Profil', key: 'profile' },
        { label: 'Mission', key: 'phase', formatter: (val: string, key?: string, item?) => val.toUpperCase() },
        { label: 'Quantité (demie journée)', key: 'quantity', tdClass: 'moneyCell' },
        { label: 'Studio du projet', key: 'projectStudio' },
        { label: "Studio de l'employé", key: 'employeeStudio' },
        {
            label: 'Montant',
            key: 'total',
            tdClass: 'moneyCell',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)
        }
    ];

    private fieldsDetails: Array<IBootstrapTableColumn | string> = [
        { label: 'Date', key: 'date', formatter: (val) => new Date(val).toLocaleDateString('fr-FR') },
        { label: 'Nombre', key: 'nombre', formatter: (val) => parseFloat(val).toFixed(3) },
        {
            label: 'Taux',
            key: 'taux',
            thClass: 'headerClass',
            tdClass: 'moneyCell',
            formatter: (val) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(
                    val
                )
        },
        {
            label: 'Total',
            key: 'total',
            thClass: 'headerClass',
            tdClass: 'moneyCell',
            formatter: (val) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(
                    val
                )
        }
    ];

    get formIsValid(): boolean {
        return true;
    }

    get appTokenMgr(): IEmployeeAppRoleManager {
        return appTokenMgr;
    }

    get projectOptions(): ISelectListOption[] {
        const list = vxm.project.dropdownProjectListOptions;
        return list.map((x) => {
            return {
                id: x.id,
                code: x.trigram,
                label: x.designation,
                concat: x.trigram + '_' + x.designation
            };
        });
    }

    get sumTotal(): number {
        let res: number = 0;
        this.imputations?.forEach((element) => {
            res += element.total ?? 0;
        });
        return res;
    }

    @Watch('selectedProjectId')
    async setContractLinesList(newVal: number | null): Promise<void> {
        if (newVal == null) {
            this.selectedContractLineId = null;
            return;
        }
        const call = await contractApi.getAllContractLinesByProjectId((this.selectedProjectId ?? 0) + '');
        this.contractLineOptions = call.datas?.map((x, index) => {
            const phase: ISelectListOption = {
                id: x.id,
                code: 'CO' + index,
                label: x.designation ?? ''
            };
            return phase;
        });
    }

    async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Imputations');
        vxm.app.changeTitleExt('Restitution');
        vxm.user.checkIsAdmin();

        this.getStudioList();
        this.isBusy = false;
        if (this.$route.params.projectId != null) {
            this.selectedProjectId = Number(this.$route.params.projectId);
            this.readonly = true;
            this.autoload = true;
            this.$nextTick(() => {
                this.getImputationList();
            });
        }
    }

    private dateDisabled(ymd, date: Date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        const weekday = date.getDay();
        // Return `true` if the date should be disabled
        return weekday === 0 || weekday === 6;
    }

    private selectedUserEvent(event: CustomEvent) {
        if (event && event.detail && event.detail.length > 0) {
            this.filterUserIds = event.detail.map((x) => x.id);
        } else if (event && event.detail && event.detail.length === 0) {
            this.filterUserIds = [];
        }
    }

    async getImputationsDetaillees(pEmployeeId: string, planificationPhaseProfileId: number, d: any): Promise<void> {
        if (!d.detailsShowing) {
            const payload: IImputationRecapFilter = {} as IImputationRecapFilter;
            payload.projectId = this.selectedProjectId;
            payload.contractLineId = this.selectedContractLineId;
            payload.employeeId = pEmployeeId;
            payload.planificationPhaseProfileId = planificationPhaseProfileId;
            payload.fromDate = this.fromDate;
            payload.toDate = this.toDate;
            payload.phase = d.item.phase;
            let test = this.imputationsDetaillees[pEmployeeId+'-'+d.item.phase];
            if (test === undefined || test === null) {
                test = {};
                this.imputationsDetaillees[pEmployeeId+'-'+d.item.phase] = test;
            }
            let imp = test[planificationPhaseProfileId];
            if (imp === undefined || imp === null) {
                imp = [];
                test[planificationPhaseProfileId] = imp;
                const imputationCallDataDetaillee = await imputationApi.getImputationsDetaillees(payload);
                if (isCallValidAndNotCancelled<IImputationsDetaillees[]>(imputationCallDataDetaillee)) {
                    test[planificationPhaseProfileId] = imputationCallDataDetaillee!.datas!;
                }
            }

            this.filterIsApply = true;
        }
        this.$nextTick(() => {
            d.toggleDetails();
        });
    }

    async getImputationList(): Promise<void> {
        this.imputationsDetaillees = {};
        const payload: IImputationRecapFilter = {} as IImputationRecapFilter;
        payload.projectId = this.selectedProjectId;
        payload.contractLineId = this.selectedContractLineId;
        payload.employeeId = this.filterUserIds && this.filterUserIds.length > 0 ? this.filterUserIds[0] : null;
        payload.fromDate = this.fromDate;
        payload.toDate = this.toDate;
        payload.studioId = this.selectedStudioId;
        this.isBusy = true;
        const imputationCallData = await imputationApi.getImputationRecapList(payload);
        if (isCallValidAndNotCancelled<IImputationRecap[]>(imputationCallData)) {
            this.imputations = imputationCallData?.datas;
        }
        if (this.imputations && this.imputations.length === 0) {
            this.noDataFound = true;
        } else {
            this.noDataFound = false;
        }
        this.isBusy = false;
        this.filterIsApply = true;
    }

    async exportImpRestData(): Promise<void> {
        const payload: IImputationRecapFilter = {} as IImputationRecapFilter;
        payload.projectId = this.selectedProjectId;
        payload.contractLineId = this.selectedContractLineId;
        payload.employeeId = this.filterUserIds && this.filterUserIds.length > 0 ? this.filterUserIds[0] : null;
        payload.fromDate = this.fromDate;
        payload.toDate = this.toDate;
        await this.generateReport(
            imputationApi.exportImpRestData({ data: payload } as any),
            `Imputations_Restitution_${this.formatDate(new Date())}.xlsx`
        );
    }

    async getStudioList(): Promise<void> {
        const studioCallData = await studioApi.getAllBase();
        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.studioOptions = studioCallData?.datas?.map((x) => {
                const studioList: ISelectListOption = {
                    id: x.id ?? 0,
                    code: x.code ?? '',
                    label: x.label ?? ''
                };
                return studioList;
            });
        }
    }

    private formatDate(date: Date | null): string | null{
        if (date)
            return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
        else    
            return null
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }
}
